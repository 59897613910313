<template>
  <b-card-code title="Modifier un client">
    <b-card-text>
      <span>Veuillez modifier les coordonnées de client </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form v-if="client">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom entreprise / client"
            >
              <validation-provider
                #default="{ errors }"
                name="company_name"
                rules="required"
              >
                <b-form-input
                  v-model="client.company_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom entreprise"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="client.email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Type"
              label-for="type"
            >
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
                <v-select
                  v-model="client.type"
                  :clearable="false"
                  label="Type"
                  :options="types"
                  placeholder="Type"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Numéro mobile"
            >
              <validation-provider
                #default="{ errors }"
                name="mobile"
                rules="required"
              >
                <b-form-input
                  v-model="client.mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Numéro fix"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  v-model="client.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Numéro fix"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="website"
            >
              <validation-provider
                #default="{ errors }"
                name="website"
                rules="required"
              >
                <b-form-input
                  v-model="client.website"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Site web"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Fax"
            >
              <validation-provider
                #default="{ errors }"
                name="fax"
                rules="required"
              >
                <b-form-input
                  v-model="client.fax"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Fax"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adresse"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-input
                  v-model="client.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="adresse"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="MF/CIN"
            >
              <validation-provider
                #default="{ errors }"
                name="fax"
                rules="required"
              >
                <b-form-input
                  v-model="client.tax_identification_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="MF/CIN"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editClient"
            >
              Modifier
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
// import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    vSelect,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  data() {
    return {
      id: '',
      required,
      types: [
        'client physique',
        'client morale',
      ],
      clients: [],
    }
  },
  computed: {
    client() {
      return this.clients.find(
        client => client.id === this.$route.params.id,
      )
    },
  },
  created() {
    this.getClients()
    this.id = this.$route.params.id
  },
  methods: {
    async getClients() {
      await axios.get('/customers/').then(response => {
        this.clients = response.data
      })
    },
    async editClient() {
      await axios
        .put(
          `/customers/update/${this.id}/`,
          {
            company_name: this.client.company_name,
            first_name: this.client.first_name,
            last_name: this.client.last_name,
            email: this.client.email,
            phone: this.client.phone,
            address: this.client.address,
            website: this.client.website,
            fax: this.client.fax,
            mobile: this.client.mobile,
            type: this.client.type,
            tax_identification_number: this.client.tax_identification_number,
          },
        )
        .then(() => {
          this.$router.push('/clients/')
          setTimeout(() => {
            this.showToast('success', 'top-center', 'Client modifié avec succés')
          }, 1000)
        })
        .catch(err => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', err.toString())
          }, 1000)
        })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
